import { FeatureCollection } from 'geojson'
import { includes, isEmpty } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

import { ListShapesResultModel } from '@/modules/api/openapi/models/ListShapesResultModel'
import { internalApi } from '@/modules/api/request'
import i18n from '@/modules/i18n/i18n'
import { monitorMessage } from '@/modules/monitoring'
import appUIStore from '@/stores/appUIStore'

import Shape from './shape'

export type Level = 'l1' | 'blockface' | 'cfad' | 'parking_districts' | null
export type VehicleCountsStatus = 'needs_processing' | 'currently_processing' | 'up_to_date' | null

export type LayerDocument = {
  shape_layer_uuid: string
  editable: boolean
  level: Level
  layer_name: string
  layer_slug: string
  buffers: number[] // an array of buffer distances to compute in meters
  intersect_route: boolean // whether to run shape intersection
  hourly_processing: string[] // whether to run hourly aggregations or daily
  parking_duration_filters: number[] // an array of numbers to count parking events above certain durations
  vehicle_counts: boolean
  vehicle_counts_status: VehicleCountsStatus
}

// this is dumb but it's basically _.map that works on mobx observable.maps
// I couldn't find a better idiom for this
export const mobxMapMap = (obj: any, func: any) => {
  const ret = []
  for (const [key, value] of obj.entries()) {
    ret.push(func(value, key))
  }
  return ret
}

/**
 * @deprecated please use the useLayer Hook
 */
export default class Layer {
  shapes = observable.map<string, Shape>() // an array of Shape objects
  doc: LayerDocument

  constructor(doc: LayerDocument) {
    makeObservable(this, {
      layerUUID: computed,
      editable: computed,
      level: computed,
      layerName: computed,
      allowedBuffers: computed,
      hasIntersect: computed,
      hasHourlyTrips: computed,
      allowedParkingDurations: computed,
      allowedParkingDurationsOptions: computed,
      vehicleCounts: computed,
      vehicleCountsStatus: computed,
      setShapes: action,
      geojson: computed({ keepAlive: true }),
    })
    this.doc = doc
  }

  getShapeName(shape_uuid: string): string | undefined {
    return this.shapes.get(shape_uuid)?.name || undefined
  }

  getShapeId(shape_uuid: string): string | undefined {
    return this.shapes.get(shape_uuid)?.shapeId
  }

  get layerUUID(): string {
    return this.doc.shape_layer_uuid
  }

  get editable(): boolean {
    return this.doc.editable
  }

  get level(): Level {
    return this.doc.level
  }

  get layerName(): string {
    return this.doc.layer_name
  }

  get allowedBuffers(): number[] {
    return this.doc.buffers
  }

  get hasIntersect(): boolean {
    return this.doc.intersect_route
  }

  get hasHourlyTrips(): boolean {
    return includes(this.doc.hourly_processing, 'trips')
  }

  get allowedParkingDurations(): number[] {
    return isEmpty(this.doc.parking_duration_filters)
      ? [1440, 2880, 4320, 14400]
      : this.doc.parking_duration_filters
  }

  get allowedParkingDurationsOptions() {
    return [
      {
        value: undefined,
        text: i18n.t('shapeLayer.noDurationFilter', 'No Duration Filter'),
        key: 'No filter',
      },
      ...this.allowedParkingDurations.map(duration => {
        const text =
          duration > 4320
            ? i18n.t('common.daysWithCount', '{{count}} days', { count: duration / 1440 })
            : i18n.t('common.hoursWithCount', '{{count}} hours', { count: duration / 60 })
        return {
          value: duration.toString(),
          text: text,
          key: duration.toString(),
        }
      }),
    ]
  }

  get vehicleCounts(): boolean {
    return this.doc.vehicle_counts
  }

  get vehicleCountsStatus(): VehicleCountsStatus {
    return this.doc.vehicle_counts_status
  }

  setShapes(shapes: ListShapesResultModel[]) {
    shapes.forEach(shape => {
      this.shapes.set(
        shape.shape_uuid,
        new Shape(shape, shape.shape_uuid, appUIStore.region?.timezone)
      )
    })
  }

  loadShapesFromDb(forceLoad: boolean = false): Promise<FeatureCollection> {
    // forceLoad: used when a new shape is created

    if (!forceLoad && this.shapes.size > 0) {
      // already have them here don't need to load them again
      return Promise.resolve(this.geojson)
    }

    return new Promise(resolve => {
      internalApi.shapes
        .listShapesDeprecated({
          requestBody: {
            region_id: appUIStore.region?.regionId as string,
            shape_layer_uuid: this.layerUUID,
          },
        })
        .then(response => {
          this.setShapes(response.data)
          resolve(this.geojson)
        })
        .catch(err => monitorMessage(`shapes error: ${err}`))
    })
  }

  /**
   * keepAlive: true so geojson doesn't recompute every time its accessed
   * See: https://mobx.js.org/computeds.html#keepalive
   * See: https://medium.com/terria/when-and-why-does-mobxs-keepalive-cause-a-memory-leak-8c29feb9ff55
   * Potential for memory leak: the lifetime of a computed property with keepAlive: true
   * will be extended to the longest lifetime of any of the observables it accesses.
   */
  get geojson(): FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: mobxMapMap(this.shapes, (shape: Shape) => shape.geojson),
    }
  }
}
