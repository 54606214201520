import { FC, PropsWithChildren, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router'
import { observer } from 'mobx-react'

import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import evaluationMapStore from '@/modules/evaluationMap/evaluationMapStore'
import layerStore from '@/modules/layers/layerStore'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PARAMS } from '@/modules/urlRouting/paths'
import appUIStore from '@/stores/appUIStore'

/**
 * Set regionId in the appUIStore when it changes in the path
 */
const SyncRegionIdParam: FC<PropsWithChildren> = observer(({ children }) => {
  const location = useLocation()
  const matchObject = matchPath<'regionId', string>(`/:${PARAMS.REGION_ID}/*`, location.pathname)
  const regionIdParam = matchObject?.params?.regionId
  const { data: region } = useCurrentRegion<RegionResponse>()

  useEffect(() => {
    if (regionIdParam) {
      appUIStore.setRegion(region)
      layerStore.initializeRegion().finally(() => {
        if (region.allowedMaps.length) {
          evaluationMapStore.updateMetro()
        }
      })
    }

    return () => {
      layerStore.clearRegion()
      appUIStore.clearRegion()
    }
  }, [regionIdParam])

  return children
})

export default SyncRegionIdParam
