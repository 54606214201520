import { FC } from 'react'

import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import ProfileModal from '@/modules/user/profileModal'

const GlobalModal: FC = () => {
  const { id, setId } = useGlobalModalProvider()
  return (
    <>
      {id === GlobalModalId.PROFILE_SETTINGS && <ProfileModal onClose={() => setId(undefined)} />}
    </>
  )
}

export default GlobalModal
