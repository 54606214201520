const METERS_PER_MILE = 1609.344
const FEET_PER_KILOMETER = 3280.84
export const CM_PER_FOOT = 30.48
export const CM_PER_METER = 100
export const FEET_PER_MILE = 5280

/*
 * Converts meters to kilometers or miles
 * */
export const metersToKmOrMiles = (meters: number = 0, isMetric: boolean = false) => {
  if (meters === 0) return 0
  if (isMetric) return parseFloat((meters / 1000).toFixed(2))
  return parseFloat((meters / METERS_PER_MILE).toFixed(2))
}

/**
 * To get the trend color, break up the data into 2 halves and compare the first half total to the
 * second half total. If the second half is lower, then the data is trending down. If there are an
 * odd number of data points, the middle value won't be counted in either half because it can be
 * considered shared between both halves.
 */
export const getIsRangeTrendingUp = (range: number[]) => {
  const firstHalfTotal = range
    .slice(0, Math.floor(range.length / 2))
    .reduce((total, dataPoint) => total + dataPoint, 0)
  const secondHalfTotal = range
    .slice(Math.ceil(range.length / 2), range.length)
    .reduce((total, dataPoint) => total + dataPoint, 0)

  return secondHalfTotal >= firstHalfTotal
}

export const kilometersToMetersOrFeet = (kilometers: number = 0, isMetric: boolean = false) => {
  if (kilometers === 0) return `0 ${isMetric ? 'm' : 'ft'}`
  return isMetric
    ? (kilometers * 1000).toFixed(1) + ' m'
    : Math.round(kilometers * FEET_PER_KILOMETER) + ' ft'
}

export const roundPreciseNumber = (value: number, precision: number) => {
  return parseFloat(value.toPrecision(precision))
}

export const getArrayBounds = (values: number[]) =>
  values.reduce(
    ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
    [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
  )
