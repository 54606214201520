import classNames from 'classnames'

import Button, { ButtonProps } from '../Button'

import './index.scss'

export const ToggleButton = ({ className, active = false, ...props }: ButtonProps) => {
  return (
    <Button
      active={active}
      className={classNames('populus-toggle-button', className)}
      secondary
      {...props}
    />
  )
}
