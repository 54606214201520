import { ReactElement } from 'react'
import { find } from 'lodash'

import { formatNumber, formatPercent } from '@/common/utils/formatting'
import appUIStore from '@/stores/appUIStore'

import globalStore from './evaluationMapStore'

export const formatDistance = (count: number) => (count ? formatNumber(count, 2) : 'n/a')

export const formatCount = (count: number) => {
  if (count > 0 && count < 0.1) return '0.1'
  if (count > 0 && count < 1) return formatNumber(count, 1)
  return formatNumber(count)
}

/**
 @param id is the id of the feature for which to render a hover
 @param localStore is used for testing - we still use globals for the live app
 */
export function getFeaturePopupContent(
  featureId: string,
  localStore?: typeof globalStore
): ReactElement | undefined {
  const evaluationMapStore = localStore || globalStore
  const features = evaluationMapStore.geojson.features

  const obj = find(features, o => o?.properties?.id === featureId)
  if (!obj) return undefined

  const shape = evaluationMapStore.shapeData?.[obj.properties?.id]
  const selectedShapeName =
    evaluationMapStore.shapeFilterName && evaluationMapStore.selectedShape
      ? evaluationMapStore.shapeData?.[evaluationMapStore.selectedShape]?.shape_name
      : undefined

  const attributeText = evaluationMapStore.aggregationFilterText
  let shapeFilterText = ''
  if (selectedShapeName)
    shapeFilterText =
      evaluationMapStore.shapeFilterName === 'origin'
        ? ` from ${selectedShapeName}`
        : ` to ${selectedShapeName}`

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return (
    <>
      <h5>{obj?.properties?.name}</h5>
      <span>
        <b>
          {evaluationMapStore.aggregation.isDistance
            ? `${formatDistance(obj.properties?.value)} ${appUIStore.distanceUnits}`
            : formatCount(obj.properties?.value)}
        </b>{' '}
        {attributeText}
        {shapeFilterText}
      </span>
      {!(
        evaluationMapStore.aggregation.isDistance || evaluationMapStore.aggregation.isDuration
      ) && (
        <>
          <br />
          <span>
            <b>{formatPercent(obj.properties?.percent / 100)}</b> of {attributeText}
            {shapeFilterText}
          </span>
        </>
      )}
      {shape?.median_income && (
        <>
          <br />
          <span>{`${(shape.pct_nonwhite! * 100).toFixed(2)}% Non-White`}</span>
          <br />
          <span>{`Median income is ${formatter.format(shape.median_income)}`}</span>
        </>
      )}
    </>
  )
}
