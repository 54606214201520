import { addBreadcrumb, captureException, captureMessage, withScope } from '@sentry/react'
import { Extras } from '@sentry/types'

export const monitorMessage = (message: string, extra?: Extras) => {
  console.error(`Monitoring Message: ${message}`, extra)

  withScope(scope => {
    if (extra) scope.setExtras(extra)
    captureMessage(message, 'error')
  })
}
export const monitorException = (exception: any, extra?: Extras) => {
  console.error(`Monitoring Exception: `, exception, extra)

  withScope(scope => {
    if (extra) scope.setExtras(extra)
    captureException(exception)
  })
}

export const monitorBreadcrumb = (message: string, data?: Record<string, any>) => {
  if (import.meta.env.DEV && !import.meta.env.VITEST)
    console.debug('Monitoring Breadcrumb', message, data)

  addBreadcrumb({ message, data })
}
