/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MobilityMetricsExternalResponse } from '../models/MobilityMetricsExternalResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MobilityMetricsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Mobility Metrics External
   * Get External Mobility Metrics for a region within a certain date period,
   * with an option to specify operator and/or vehicle type.
   * @returns MobilityMetricsExternalResponse Successful Response
   * @throws ApiError
   */
  public getMobilityMetricsExternal({
    regionId,
    startDate,
    endDate,
    vehicleType,
    operator,
    scooterReplacementRate,
    eBikeReplacementRate,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string | null
    /**
     * Include routes for this operator
     */
    operator?: string | null
    scooterReplacementRate?: number | string
    eBikeReplacementRate?: number | string
  }): CancelablePromise<MobilityMetricsExternalResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mobility_metrics',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        vehicle_type: vehicleType,
        operator: operator,
        scooter_replacement_rate: scooterReplacementRate,
        e_bike_replacement_rate: eBikeReplacementRate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
