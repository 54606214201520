import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router'
import { observer } from 'mobx-react'

import { useSetDocumentTitle } from '@/common/hooks'
import { BackButton } from '@/components/componentLibrary'
import { PolicyDetailsTemplate } from '@/components/layouts'
import { useMobilityPoliciesAccess } from '@/modules/permissions/pageAccessHooks'
import { EditHeader } from '@/modules/policiesV1/policyLibrary/components/headers/EditHeader'
import { Form } from '@/modules/policiesV1/policyLibrary/components/PolicyDetails/Form'
import { PolicyDetailsMap } from '@/modules/policiesV1/policyLibrary/components/PolicyDetailsMap'
import { PolicyInfoModal } from '@/modules/policiesV1/policyLibrary/components/PolicyInfoModal'
import { PolicyNotFound } from '@/modules/policiesV1/policyLibrary/components/PolicyNotFound'
import {
  PolicyEditContext,
  PolicyEditStore,
} from '@/modules/policiesV1/policyLibrary/policyEditStore'
import { getModalText } from '@/modules/policiesV1/policyLibrary/utils/modalText'
import AccessGatekeeper from '@/modules/urlRouting/AccessGatekeeper'
import { useLocationState, useNavigateOnRegionChange } from '@/modules/urlRouting/hooks'
import { PATHS, TO_PARENT_PATH } from '@/modules/urlRouting/paths'
import { SearchParam } from '@/modules/urlRouting/types'

import { EditState } from './constants'

const PolicyDetailsEditPage: FC<{ editState: EditState }> = props => {
  const hasAccess = useMobilityPoliciesAccess()
  return (
    <AccessGatekeeper hasAccess={hasAccess}>
      <PolicyDetailsEditPageContent {...props} />
    </AccessGatekeeper>
  )
}

const PolicyDetailsEditPageContent: FC<{ editState: EditState }> = observer(({ editState }) => {
  useSetDocumentTitle('Mobility Manager - Policies')
  useNavigateOnRegionChange({ to: PATHS.MOBILITY.POLICIES._PATH, replace: true })

  const [policyEditStore, setPolicyEditStore] = useState<PolicyEditStore>()

  const { t } = useTranslation()

  const navigate = useNavigate()
  const { policyId } = useParams()
  const [searchParams] = useSearchParams()
  const { policyJson } = useLocationState()

  useEffect(() => {
    const createStoreAndFetch = async () => {
      const store = new PolicyEditStore()
      setPolicyEditStore(store)
      switch (editState) {
        case EditState.EDIT:
          store.fetch({ policyJson, uuid: policyId })
          break
        case EditState.DUPLICATE:
          store.fetch({
            policyJson,
            uuid: searchParams.get(SearchParam.POLICY_ID) || undefined,
            duplicate: true,
          })
          break
        case EditState.CREATE:
          store.create({
            policyType: searchParams.get(SearchParam.POLICY_TYPE),
            policyName: searchParams.get(SearchParam.POLICY_NAME),
          })
          return
      }
    }
    createStoreAndFetch()
  }, [editState, policyId, policyJson, searchParams, setPolicyEditStore])

  if (!policyEditStore) return null
  if (policyEditStore.policyNotFound) return <PolicyNotFound />
  if (policyEditStore.savedPolicyId)
    return (
      <Navigate
        to={`${TO_PARENT_PATH}/${policyEditStore.isNew ? policyEditStore.savedPolicyId : ''}`}
        state={{ policyJson: policyEditStore?.originalPolicy?.toJson }}
      />
    )

  const { policy } = policyEditStore

  return (
    <PolicyEditContext.Provider value={policyEditStore}>
      {policyEditStore.modalProps && <PolicyInfoModal {...policyEditStore.modalProps} />}

      <PolicyDetailsTemplate
        disabled={policyEditStore.isFetching || policyEditStore.isSaving}
        backLinkContent={
          <BackButton
            onClick={() =>
              policyEditStore.setModalProps({
                ...getModalText().INFO_CANCEL,
                onCancel: policyEditStore.clearModalProps,
                onConfirm: () => {
                  policyEditStore.clearModalProps()
                  navigate(
                    policyEditStore.isNew ? TO_PARENT_PATH : `${TO_PARENT_PATH}/${TO_PARENT_PATH}`
                  )
                },
              })
            }
            text={t('policiesLibrary.backToPolicies', 'Back To Policies')}
          />
        }
        headerContent={<EditHeader />}
        mapContent={<PolicyDetailsMap shapeLayerUUID={policy?.shapeLayerUUID || undefined} />}
        detailsContent={
          policy && (
            <Form
              editable
              policy={policy}
              errors={policyEditStore.formErrors}
              clearErrors={policyEditStore.clearFormErrors}
              setErrors={policyEditStore.setFormErrors}
            />
          )
        }
      />
    </PolicyEditContext.Provider>
  )
})

export default PolicyDetailsEditPage
