import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { formatText } from '@/common/utils/text'
import { OperatorResponse } from '@/modules/api/openapi/models/OperatorResponse'
import { queryClientInstance } from '@/modules/api/queryClient'
import { internalApi } from '@/modules/api/request'
import { fileFormats } from '@/modules/api/types'
import { OpenApiQueryParamsOmitRegionId } from '@/modules/api/types'
import { saveFile } from '@/modules/api/util'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'

export const useRoutes = (
  searchParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.mobility.routes>
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/routes`, searchParams],
    queryFn: async () => await internalApi.mobility.routes({ regionId, ...searchParams }),
    placeholderData: [],
  })
}

export const useRoutesDownload = (
  searchParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.mobility.routes>
) => {
  const {
    data: { regionId, text: regionText },
  } = useCurrentRegion()
  const { t } = useTranslation()

  return async (outputFormat: keyof typeof fileFormats) => {
    const data = await internalApi.mobility.routes({
      regionId,
      ...searchParams,
      accept: fileFormats[outputFormat],
    })

    const fileName = t(
      'routesMap.routesDownloadFilename',
      'Routes Data - {{region}} - {{startDate}} to {{endDate}}',
      {
        region: regionText,
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
      }
    )
    saveFile(data, `${fileName}.${outputFormat}`, outputFormat)
  }
}

export const useRouteStatistics = (
  searchParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.mobility.routeStatistics>
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const { isFetched: routeFetched } = useRoutes(searchParams)

  return useQuery({
    queryKey: [`/regions/${regionId}/routes/statistics`, searchParams],
    queryFn: async () =>
      await internalApi.mobility.routeStatistics({
        regionId,
        ...searchParams,
      }),
    enabled: routeFetched,
  })
}

export const useRoutesNetworkTypes = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/routes/network_types`],
    queryFn: async () => await internalApi.mobility.networkTypes({ regionId }),
  })
}

export const useRoutesNetworkTypeOptions = () => {
  const { t } = useTranslation()
  const { data: networkTypes } = useRoutesNetworkTypes()

  return [
    {
      key: 'full',
      value: 'full',
      text: t('routesMap.entireNetwork', 'Entire Network'),
    },
    ...(networkTypes?.map(networkType => ({
      key: networkType.edgeTagCategory,
      value: networkType.edgeTagCategory,
      text: networkType.displayName,
    })) ?? []),
  ]
}

export const useRoutesVehicleTypes = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/routes/vehicle_types`],
    queryFn: async () => await internalApi.mobility.getRoutesVehicleTypes({ regionId }),
  })
}

export const useRoutesVehicleTypeOptions = () => {
  const { t } = useTranslation()
  const { data: vehicleTypes } = useRoutesVehicleTypes()

  return [
    {
      key: 'all',
      value: 'all',
      text: t('common.allVehicleTypes', 'All Vehicle Types'),
    },
    ...(vehicleTypes?.map(vehicleType => ({
      value: vehicleType,
      text: formatText(t(`common.vehicleType_${vehicleType}`, vehicleType)),
    })) ?? []),
  ]
}

export const useRoutesOperators = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/routes/operators`],
    queryFn: async () => {
      const operatorsResponse = await internalApi.mobility.getRoutesOperators({ regionId })

      operatorsResponse.items.forEach(operator =>
        queryClientInstance.setQueryData<OperatorResponse>([`/operator/${operator.slug}`], operator)
      )

      return operatorsResponse
    },
  })
}

export const useRoutesOperatorsOptions = () => {
  const { t } = useTranslation()
  const { data: operators } = useRoutesOperators()

  return [
    {
      key: 'all',
      value: 'all',
      text: t('common.allOperators ', 'All Operators'),
    },
    ...(operators?.items.map(operator => ({
      key: operator.slug,
      value: operator.slug,
      text: operator.name,
    })) ?? []),
  ]
}

export const useRoutesDateRange = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/routes/date_range`],
    queryFn: async () => await internalApi.mobility.getDateRange({ regionId }),
  })
}

export const useCrashData = (
  searchParams: OpenApiQueryParamsOmitRegionId<
    typeof internalApi.regions.getTransitCrashDataForRegion
  >
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/crash_data`, searchParams],
    queryFn: async () =>
      await internalApi.regions.getTransitCrashDataForRegion({
        regionId,
        ...searchParams,
      }),
  })
}
