import { FocusEvent, MouseEvent, ReactNode, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { isEmpty, isString, xor } from 'lodash'
import {
  CheckboxProps as UICheckboxProps,
  Dropdown as UIDropdown,
  DropdownItemProps as UIDropdownItemProps,
  DropdownProps as UIDropdownProps,
} from 'semantic-ui-react'

import { Checkbox } from '../Checkbox'
import { Field } from '../Field'
import { Icon, IconName } from '../Icon'
import { TextStyle } from '../sharedTypes'
import Tag from '../Tag'
import { Text } from '../Text'

import './index.scss'

export type Option<T = string> = {
  key?: T
  text: string
  value?: T | undefined
  description?: string
  disabled?: boolean
}
type UIEvent = SyntheticEvent<HTMLElement, Event>

export interface DropdownProps {
  onChange: (value?: string | (string | undefined)[]) => void
  options: Option[]
  className?: string
  direction?: UIDropdownProps['direction']
  disabled?: boolean
  error?: boolean
  name?: string
  onBlur?: (e: FocusEvent<HTMLElement, Element>, data: UIDropdownProps) => void
  onFocus?: (e: UIEvent, data: UIDropdownProps) => void
  placeholder?: string
  selectAllLabel?: string
  trigger?: ReactNode
  value?: string | string[] | undefined
  fullOptions?: Option[]
  titleText?: string
  id?: string
  halfSize?: boolean
}

export interface DropdownItemProps {
  active?: boolean
  className?: string
  disabled?: boolean
  icon?: IconName
  onClick?: (event: MouseEvent, data: UIDropdownItemProps) => void
  text?: string
  description?: string
  textStyle?: TextStyle
  value?: string
}

interface TriggerProps {
  error?: boolean
  className?: string
  isOpen: boolean
  onChange: (value?: string | string[]) => void
  onItemClose: (value: string) => void
  options: Option[]
  placeholder?: string
  value?: string | string[]
  fullOptions?: Option[]
}

interface SelectAllProps {
  checked: boolean
  label: string
  onChange: (event: UIEvent, data: UICheckboxProps) => void
}

const Arrow = ({ up }: { up: boolean }) => (
  <Icon className="dropdown-arrow" color="black-1" icon={`Arrow${up ? 'Up' : 'Down'}`} />
)

const ClearAll = ({ onClick }: { onClick: (e: SyntheticEvent) => void }) => {
  const { t } = useTranslation()

  return (
    <div className="clear-all">
      <Text link onClick={onClick} styleType="link">
        {t('common.clearAll', 'clear all').toLocaleUpperCase()}
      </Text>
    </div>
  )
}

export const DropdownItem = ({
  active,
  className,
  icon,
  text,
  textStyle,
  description,
  ...props
}: DropdownItemProps) => {
  return (
    <UIDropdown.Item
      className={classNames('populus-dropdown-item', active && 'selected', className)}
      {...props}
    >
      <div className="flex items-center">
        {icon && <Icon className="item-icon" color="black-0" icon={icon} />}

        <Text
          className={classNames('dropdown-item-text', icon && 'icon-text')}
          styleType={textStyle ? textStyle : icon ? 'button-light' : 'body'}
        >
          {text}
        </Text>
      </div>
      {description && (
        <Text
          className={classNames('dropdown-item-text', icon && 'icon-text', 'description-text')}
          styleType={textStyle ? textStyle : icon ? 'button-light' : 'body-light'}
        >
          {description}
        </Text>
      )}
      {active ? <Icon className="item-checkmark" color="ocean-1" icon="Checkmark" /> : null}
    </UIDropdown.Item>
  )
}

export const DropdownMenu = ({ className, halfSize, ...props }: UIDropdownProps) => {
  return (
    <UIDropdown.Menu
      className={classNames(
        'populus-dropdown-menu',
        halfSize && 'populus-dropdown-menu-half',
        className
      )}
      {...props}
    />
  )
}

const Trigger = ({
  isOpen,
  className,
  onChange,
  onItemClose,
  options,
  placeholder,
  value,
  fullOptions,
}: TriggerProps) => {
  const showTextValue = isString(value) || isEmpty(value)
  const textValue = (
    <Text styleType="body">
      {isString(value) ? activeText(fullOptions ? fullOptions : options, value) : placeholder}
    </Text>
  )
  const multipleValues = (
    <div className="multiple-values-container">
      {Array.isArray(value) &&
        value.map(val => (
          <Tag
            key={val}
            onClick={(e: SyntheticEvent) => e.stopPropagation()}
            onClose={() => onItemClose(val)}
            text={activeText(fullOptions ? fullOptions : options, val)}
          />
        ))}
    </div>
  )
  const clearAll = (
    <ClearAll
      onClick={(e: SyntheticEvent) => {
        e.stopPropagation()
        onChange([])
      }}
    />
  )
  return (
    <div
      className={classNames(
        'populus-dropdown-trigger',
        !showTextValue && (value as string[]).length > 1 && 'show-clear-all',
        className
      )}
    >
      {showTextValue ? textValue : multipleValues}
      <Arrow up={isOpen} />
      {!showTextValue && (value as string[]).length > 1 && clearAll}
    </div>
  )
}

const SelectAll = ({ checked, label, onChange }: SelectAllProps) => {
  return (
    <Checkbox
      checked={checked}
      className="populus-dropdown-checkbox"
      label={label}
      onChange={onChange}
    />
  )
}

export const Dropdown = ({
  onChange,
  options,
  className,
  onBlur,
  placeholder,
  selectAllLabel,
  trigger,
  fullOptions,
  titleText,
  halfSize,
  ...props
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => setIsOpen(!isOpen)

  const removeMultipleValue = (value: string) =>
    onChange((props.value as string[]).filter(val => val !== value))

  const addMultipleValue = (value: string) => onChange([...(props.value as string[]), value])

  const onItemClick = (event: MouseEvent, data: UIDropdownItemProps) => {
    const value = data.value as string
    if (Array.isArray(props.value)) {
      event.stopPropagation()
      props.value.includes(value) ? removeMultipleValue(value) : addMultipleValue(value)
    } else {
      onChange(value as string)
    }
  }

  const allOptions = options.map(option => option.value)

  const onSelectAllChange = (e: UIEvent, data: UICheckboxProps) => {
    if (data.checked) onChange(allOptions)
    else onChange([])
  }

  const dropdown = (
    <>
      <UIDropdown
        className={classNames('populus-dropdown', halfSize && 'populus-dropdown-half', className)}
        icon={null}
        onBlur={(e: FocusEvent<HTMLElement, Element>, data: UIDropdownProps) => {
          setIsOpen(false)
          onBlur && onBlur(e, data)
        }}
        onClick={onClick}
        open={isOpen}
        multiple={Array.isArray(props.value)}
        trigger={
          trigger || (
            <Trigger
              className={className}
              isOpen={isOpen}
              onChange={onChange}
              onItemClose={removeMultipleValue}
              options={options}
              placeholder={placeholder}
              value={props.value}
              fullOptions={fullOptions}
            />
          )
        }
        {...props}
      >
        <DropdownMenu halfSize={halfSize}>
          {options?.map(({ description, text, value, ...optionProps }) => (
            <DropdownItem
              active={
                Array.isArray(props.value)
                  ? props.value.includes(value || '')
                  : value === props.value
              }
              key={value}
              onClick={onItemClick}
              description={description}
              text={text}
              value={value}
              {...optionProps}
            />
          ))}
        </DropdownMenu>
      </UIDropdown>
      {selectAllLabel && (
        <SelectAll
          checked={xor(allOptions, props.value).length === 0}
          label={selectAllLabel}
          onChange={onSelectAllChange}
        />
      )}
    </>
  )
  return titleText ? <Field titleText={titleText}> {dropdown}</Field> : dropdown
}

function activeText(options: UIDropdownProps['options'], value?: string) {
  return options?.find(option => option.value === value)?.text as string | undefined
}
