/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationResponse } from '../models/OrganizationResponse'
import type { Page_OrganizationResponse_ } from '../models/Page_OrganizationResponse_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class OrganizationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get All Organizations
   * List all visible organizations for the requesting user
   * @returns Page_OrganizationResponse_ Successful Response
   * @throws ApiError
   */
  public getAllOrganizations({
    page = 1,
    size = 50,
    organizationName,
    organizationType,
    orderBy,
  }: {
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
    organizationName?: string | null
    organizationType?: string | null
    orderBy?: string | null
  }): CancelablePromise<Page_OrganizationResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations',
      query: {
        page: page,
        size: size,
        organization_name: organizationName,
        organization_type: organizationType,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Organization By Uuid
   * Retrieve an organization by UUID, only showing if it is available for the requesting user
   * @returns OrganizationResponse Successful Response
   * @throws ApiError
   */
  public getOrganizationByUuid({
    organizationId,
  }: {
    organizationId: string
  }): CancelablePromise<OrganizationResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/organizations/{organization_id}',
      path: {
        organization_id: organizationId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
