/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OperatorResponse } from '../models/OperatorResponse'
import type { Page_OperatorResponse_ } from '../models/Page_OperatorResponse_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class OperatorsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get All Operators
   * List all visible operators for the requesting user
   * @returns Page_OperatorResponse_ Successful Response
   * @throws ApiError
   */
  public getAllOperators({
    page = 1,
    size = 50,
    slug,
    name,
    search,
    orderBy,
  }: {
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
    slug?: string | null
    name?: string | null
    search?: string | null
    orderBy?: string | null
  }): CancelablePromise<Page_OperatorResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/operators',
      query: {
        page: page,
        size: size,
        slug: slug,
        name: name,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Operator By Slug
   * Retrieve an operator by ID, only showing if it is available for the requesting user
   * @returns OperatorResponse Successful Response
   * @throws ApiError
   */
  public getOperatorBySlug({ slug }: { slug: string }): CancelablePromise<OperatorResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/operators/{slug}',
      path: {
        slug: slug,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
