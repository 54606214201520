import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Query,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useSuspenseInfiniteQuery,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { centroid } from '@turf/centroid'
import { AllGeoJSON } from '@turf/helpers'
import camelcaseKeys from 'camelcase-keys'

import { MuiOption } from '@/components/curb/constants'
import { AppendShapesToLayer } from '@/modules/api/openapi/models/AppendShapesToLayer'
import { CreateLayerBody } from '@/modules/api/openapi/models/CreateLayerBody'
import { LayerResponse } from '@/modules/api/openapi/models/LayerResponse'
import { ListShapesResponseModel } from '@/modules/api/openapi/models/ListShapesResponseModel'
import { ListShapesResultModel } from '@/modules/api/openapi/models/ListShapesResultModel'
import { PatchShape } from '@/modules/api/openapi/models/PatchShape'
import { PostShape } from '@/modules/api/openapi/models/PostShape'
import { ShapesResultModel } from '@/modules/api/openapi/models/ShapesResultModel'
import { UpdateLayerBody } from '@/modules/api/openapi/models/UpdateLayerBody'
import { queryClientInstance, removeQueryCache } from '@/modules/api/queryClient'
import { apiV2, fastApiRequest, internalApi } from '@/modules/api/request'
import { OpenApiQueryParamsOmitRegionId } from '@/modules/api/types'
import { getNextPageParam } from '@/modules/api/util'
import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { SEGMENTS } from '@/modules/urlRouting/paths'

export const useShapesForLayer = (
  shapeLayerId?: string,
  boundaryShapeLayerUuid?: string,
  boundaryShapeUuids?: string[]
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  const queryParams = {
    region_id: regionId,
    shape_layer_uuid: shapeLayerId!,
    boundary_shape_layer_uuid: boundaryShapeLayerUuid,
    boundary_shape_uuids: boundaryShapeUuids,
  }

  return useQuery({
    queryKey: [`/shapes/list`, queryParams],
    queryFn: async () => {
      const res: ListShapesResponseModel = await internalApi.shapes.listShapesDeprecated({
        requestBody: queryParams,
      })
      return res.data
    },
    enabled: !!shapeLayerId,
  })
}

export type LayerToShapesDict = { [key: string]: ListShapesResultModel[] }

/**
 * Hook to get the shapes for one or more layers given an array of shape layer ids. Uses the same
 * react query keys as {@link useShapesForLayer} so that each layer that is fetched will be
 * individually cached.
 *
 * @param shapeLayerIds a list of layers to load shapes for
 * @returns a list of api responses' data from calling the /shapes/list endpoint multiple times
 */
export const useShapesForLayers = (shapeLayerIds: string[]) => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const productPath = useCurrentProduct()

  const results = useQueries({
    queries: shapeLayerIds.map(shapeLayerId => {
      const externalQueryParams = {
        queryKey: [`/v2/regions/${regionId}/shapes/list`, { regionId, shapeLayerId }],
        queryFn: async () => {
          const res = await apiV2.shapes.listShapesDeprecated({
            regionId,
            requestBody: { region_id: regionId, shape_layer_uuid: shapeLayerId },
          })
          return res.data
        },
      }

      const internalQueryParams = {
        queryKey: [`/shapes/list`, { regionId, shapeLayerId }],

        queryFn: async () => {
          const res = await internalApi.shapes.listShapesDeprecated({
            requestBody: { region_id: regionId, shape_layer_uuid: shapeLayerId },
          })
          return res.data
        },
      }
      return productPath === SEGMENTS.PUBLIC ? externalQueryParams : internalQueryParams
    }),
  })
  const isLoading = results.some(result => result.isLoading)
  const isError = results.some(result => result.isError)
  const error = results.find(result => result.isError && result.error)
  // Memoizing b/c useQueries returns a new array on every re-render.
  // Using a simple array of the dataUpdatedAt timestamps so that the result gets refreshed whenever the data changes.
  // See: https://github.com/TanStack/query/issues/3049#issuecomment-1253201068
  const layerToShapesDict = useMemo(() => {
    return isLoading
      ? undefined
      : results.reduce<LayerToShapesDict>((layerToShapesDict, result, index) => {
          layerToShapesDict[shapeLayerIds[index]] = result.data!
          return layerToShapesDict
        }, {})
  }, [JSON.stringify(results.map(result => result.dataUpdatedAt))])

  if (layerToShapesDict) {
    Object.keys(layerToShapesDict).map(layer =>
      layerToShapesDict[layer].map(shape => {
        productPath === SEGMENTS.PUBLIC
          ? queryClientInstance.setQueryData(
              [`/v2/regions/${regionId}/shapes/${shape.shape_uuid}`],
              camelcaseKeys(shape)
            )
          : queryClientInstance.setQueryData([`/shapes/${shape.shape_uuid}`], camelcaseKeys(shape))
      })
    )
  }

  return { isLoading, isError, error, layerToShapesDict }
}

/**
 * Hook to get the Shape layers available to a region
 * @param regionId
 * @returns
 */
export const useLayers = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.layers.getLayers> = {},
  enabled = true
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/layers`, queryParams],
    queryFn: async () => {
      const layersResponse = await internalApi.layers.getLayers({
        regionId,
        ...queryParams,
      })

      layersResponse.items.forEach(layer =>
        queryClientInstance.setQueryData<LayerResponse>(
          [`/regions/${regionId}/layers/${layer.shapeLayerUuid}`],
          layer
        )
      )

      return layersResponse
    },
    placeholderData: {
      items: [],
      total: 0,
      page: 1,
      size: 0,
    },
    enabled,
  })
}

/**
 * Hook to get the Shape layers available to a region
 * @param regionId
 * @returns
 */
export const useInfiniteLayers = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.layers.getLayers> = {}
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useInfiniteQuery({
    queryKey: [`/regions/${regionId}/layers`, queryParams],
    queryFn: async ({ pageParam }) => {
      const layersResponse = await internalApi.layers.getLayers({
        regionId,
        page: pageParam,
        ...queryParams,
      })

      layersResponse.items.forEach(layer =>
        queryClientInstance.setQueryData<LayerResponse>(
          [`/regions/${regionId}/layers/${layer.shapeLayerUuid}`],
          layer
        )
      )

      return layersResponse
    },
    initialPageParam: 1,
    getNextPageParam,
  })
}

/**
 * Hook to get the Shape layers available to a region, with Suspense
 * @param regionId
 * @returns
 */
export const useSuspenseLayers = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.layers.getLayers> = {}
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useSuspenseQuery({
    queryKey: [`/regions/${regionId}/layers`, queryParams],
    queryFn: async () => {
      const layersResponse = await internalApi.layers.getLayers({
        regionId,
        ...queryParams,
      })

      layersResponse.items.forEach(layer =>
        queryClientInstance.setQueryData<LayerResponse>(
          [`/regions/${regionId}/layers/${layer.shapeLayerUuid}`],
          layer
        )
      )

      return layersResponse
    },
  })
}

/**
 * Get layers for region formatted for Dropdown values
 * @param searchParams
 * @returns
 */
export const useLayerOptions = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.layers.getLayers> = {}
) => {
  const { t } = useTranslation()

  const { data } = useLayers(queryParams)
  const { items: layers } = data || { items: [] }

  return layers.map(layer => ({
    text: `${
      (layer.level as unknown as string) === 'l1'
        ? `${t('routesMap.primaryLayer', 'Primary Layer')}: `
        : ''
    }${layer.layerName}`,
    value: layer.shapeLayerUuid,
    key: layer.shapeLayerUuid,
    level: layer.level,
    'data-testid': layer.layerName.toLowerCase().replaceAll(' ', '-'),
  }))
}

export const useLayerOptionsMui = (
  queryParams: OpenApiQueryParamsOmitRegionId<typeof internalApi.layers.getLayers> = {}
): MuiOption[] => {
  const { t } = useTranslation()

  const { data } = useLayers(queryParams)
  const { items: layers } = data || { items: [] }

  return layers.map(layer => ({
    label: `${
      (layer.level as unknown as string) === 'l1'
        ? `${t('routesMap.primaryLayer', 'Primary Layer')}: `
        : ''
    }${layer.layerName}`,
    value: layer.shapeLayerUuid,
  }))
}

/**
 * Get the layer to use as a default when the user/feature has not specified a layer to use.
 */
export const useDefaultLayerOption = () => {
  const layerOptions = useLayerOptions()
  return layerOptions?.[0]
}

/**
 * Hook to get a Shape layer
 * @param regionId
 * @returns
 */
export const useLayer = (shapeLayerUuid?: string | undefined) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useQuery({
    queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}`],
    queryFn: async () =>
      await internalApi.layers.getLayer({ regionId, shapeLayerUuid: shapeLayerUuid! }),
    enabled: !!shapeLayerUuid,
  })
}

export const useLayersModifiedDate = (shapeLayerIds: string[]) => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const results = useSuspenseQueries({
    queries: shapeLayerIds.map(shapeLayerUuid => ({
      queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}`],
      queryFn: async () => await internalApi.layers.getLayer({ regionId, shapeLayerUuid }),
    })),
  })

  return results.reduce((previousDate, result) => {
    const modifiedTime = Date.parse(result.data.modifiedTime)
    return previousDate > modifiedTime ? previousDate : modifiedTime
  }, Date.parse('2000-01-01'))
}

export const useSuspenseLayer = (shapeLayerUuid?: string | undefined) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useSuspenseQuery({
    queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}`],
    queryFn: async () =>
      await internalApi.layers.getLayer({
        regionId,
        shapeLayerUuid: shapeLayerUuid!,
      }),
  })
}

export const useUpdateLayer = (shapeLayerUuid: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useMutation({
    mutationFn: async (requestBody: UpdateLayerBody) =>
      await internalApi.layers.updateLayer({ regionId, shapeLayerUuid, requestBody }),
    onSuccess: async data => {
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
      await queryClientInstance.setQueryData(
        [`/regions/${regionId}/layers/${data.shapeLayerUuid}`],
        data
      )
    },
  })
}

export const useCreateLayer = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useMutation({
    mutationFn: async (requestBody: CreateLayerBody) =>
      await internalApi.layers.createLayer({ regionId, requestBody }),
    onSuccess: async data => {
      await queryClientInstance.setQueryData(
        [`/regions/${regionId}/layers/${data.shapeLayerUuid}`],
        data
      )
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
    },
  })
}

export const useLayerDelete = (shapeLayerUuid: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useMutation({
    mutationFn: async () => await internalApi.layers.deleteLayer({ regionId, shapeLayerUuid }),
    onSuccess: async () => {
      await removeQueryCache({ queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}`] })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
    },
  })
}

export const useShapesByLayer = (
  shapeLayerUuid: string,
  queryParams: Partial<typeof internalApi.shapes.getShapesByLayer> = {}
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useSuspenseInfiniteQuery({
    queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}/shapes`, queryParams],
    queryFn: async ({ pageParam }) => {
      const shapesResponse = await internalApi.shapes.getShapesByLayer({
        regionId,
        shapeLayerUuid,
        page: pageParam,
        ...queryParams,
      })

      shapesResponse.items.forEach(shape =>
        queryClientInstance.setQueryData<ShapesResultModel>([`/shapes/${shape.shapeUuid}`], shape)
      )

      return shapesResponse
    },
    initialPageParam: 1,
    getNextPageParam,
  })
}

export const useInifiniteShapes = (
  shapeLayerUuid: string,
  queryParams: Partial<typeof internalApi.shapes.getShapesByLayer> = {}
) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useInfiniteQuery({
    queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}/shapes`, queryParams],
    queryFn: async ({ pageParam }) => {
      const shapesResponse = await internalApi.shapes.getShapesByLayer({
        regionId,
        shapeLayerUuid,
        page: pageParam,
        ...queryParams,
      })

      shapesResponse.items.forEach(shape =>
        queryClientInstance.setQueryData<ShapesResultModel>([`/shapes/${shape.shapeUuid}`], shape)
      )

      return shapesResponse
    },
    initialPageParam: 1,
    getNextPageParam,
  })
}

export const useShape = (id: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const productPath = useCurrentProduct()

  const externalQueryParams = {
    queryKey: [`/v2/regions/${regionId}/shapes/${id}`],
    queryFn: async () => await apiV2.shapes.getShape({ regionId, shapeUuid: id }),
  }

  const internalQueryParams = {
    queryKey: [`/shapes/${id}`],
    queryFn: async () => await internalApi.shapes.getShape({ shapeUuid: id }),
  }

  return useSuspenseQuery(
    productPath === SEGMENTS.PUBLIC ? externalQueryParams : internalQueryParams
  )
}

export const useShapeHasPoliciesV2 = (shapeUuid: string) =>
  useSuspenseQuery({
    queryKey: [`/shapes/${shapeUuid}/has_policies_v2`],
    queryFn: async () => await internalApi.shapes.getDoesShapeHavePoliciesV2({ shapeUuid }),
  })

export const useLayerHasPolicies = (shapeLayerUuid: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  const { data } = useSuspenseQuery({
    queryKey: [`/regions/${regionId}/layers/${shapeLayerUuid}/has_policies`],
    queryFn: async () =>
      await internalApi.layers.getDoesLayerHavePolicies({ regionId, shapeLayerUuid }),
  })

  return data
}

export const useShapePatch = (shapeUuid: string) =>
  useMutation({
    mutationFn: async (requestBody: PatchShape) =>
      await internalApi.shapes.patchShape({ shapeUuid, requestBody }),
    onSuccess: async data => {
      await queryClientInstance.setQueryData([`/shapes/${shapeUuid}`], data)
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/layers`),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/shapes/list`),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/curbs`),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/policies_v2`),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/rules`),
      })
    },
  })

export const useShapePost = () =>
  useMutation({
    mutationFn: async (requestBody: PostShape) =>
      await internalApi.shapes.postShape({ requestBody }),
    onSuccess: async data => {
      await queryClientInstance.setQueryData([`/shapes/${data.shapeUuid}`], data)
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/policies_v2'),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes(`/rules`),
      })
    },
  })

export const useShapeUpload = (shapeLayerUuid: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useMutation({
    mutationFn: async (requestBody: AppendShapesToLayer) =>
      await internalApi.shapes.uploadShapesToLayer({ regionId, shapeLayerUuid, requestBody }),
    onSuccess: async data => {
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
      await queryClientInstance.setQueryData(
        [`/regions/${regionId}/layers/${data.shapeLayerUuid}`],
        data
      )
    },
  })
}

export const useShapeDelete = (shapeUuid: string) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  return useMutation({
    mutationFn: async () => await internalApi.shapes.deleteShape({ shapeUuid }),
    onSuccess: async data => {
      await removeQueryCache({ queryKey: [`/shapes/${shapeUuid}`] })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/layers'),
      })
      await removeQueryCache({
        predicate: (query: Query) => (query.queryKey[0] as string).includes('/policies_v2'),
      })
      await queryClientInstance.setQueryData(
        [`/regions/${regionId}/layers/${data.shapeLayerUuid}`],
        data
      )
    },
  })
}

export const fetchShape = (id?: string | undefined) => {
  if (!id) return undefined

  // TODO: make the queryKey and queryFn reusable
  return queryClientInstance.fetchQuery({
    queryKey: [`/shape/${id}`],
    queryFn: async () =>
      await internalApi.shapes.getShape({
        shapeUuid: id!,
      }),
  })
}

export const fetchShapeCentroidLngLat = async (
  id?: string | undefined
): Promise<[number, number] | undefined> => {
  const shape = await fetchShape(id)
  if (!shape) return undefined
  return centroid(shape.geom as AllGeoJSON).geometry.coordinates as [number, number]
}

export const useLayersForSelector = (isParkingInsights?: boolean) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  const parkingInsightsQuery = {
    queryKey: [`/regions/${regionId}/curb_parking_insights/location_layers`],
    queryFn: async () =>
      await internalApi.curbParkingInsights.getParkingLocationLayers({ regionId }),
    initialPageParam: 1,
    getNextPageParam,
  }

  const layersQuery = {
    queryKey: [`/regions/${regionId}/layers`],
    queryFn: async () => {
      const layersResponse = await internalApi.layers.getLayers({ regionId })

      layersResponse.items.forEach(layer =>
        queryClientInstance.setQueryData<LayerResponse>(
          [`/regions/${regionId}/layers/${layer.shapeLayerUuid}`],
          layer
        )
      )

      return layersResponse
    },
    initialPageParam: 1,
    getNextPageParam,
  }

  return useSuspenseInfiniteQuery(isParkingInsights ? parkingInsightsQuery : layersQuery)
}
