import { FC, PropsWithChildren, useState } from 'react'

import { GlobalModalContext, GlobalModalId } from './context'

export const GlobalModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [id, setId] = useState<GlobalModalId | undefined>(undefined)
  return <GlobalModalContext.Provider value={{ id, setId }}>{children}</GlobalModalContext.Provider>
}

export default GlobalModalProvider
