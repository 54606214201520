/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeletePolicyBody } from '../models/DeletePolicyBody'
import type { GetPolicyResponse } from '../models/GetPolicyResponse'
import type { ListPoliciesBody } from '../models/ListPoliciesBody'
import type { ListPoliciesResponse } from '../models/ListPoliciesResponse'
import type { PolicyComplianceDetailDistribution } from '../models/PolicyComplianceDetailDistribution'
import type { PolicyComplianceDetailNoParking } from '../models/PolicyComplianceDetailNoParking'
import type { PolicyComplianceDetailNoRide } from '../models/PolicyComplianceDetailNoRide'
import type { PolicyComplianceDetailOperatorDropOffs } from '../models/PolicyComplianceDetailOperatorDropOffs'
import type { PolicyComplianceDetailParkingTimeLimit } from '../models/PolicyComplianceDetailParkingTimeLimit'
import type { PolicyComplianceSummary } from '../models/PolicyComplianceSummary'
import type { PolicySummary } from '../models/PolicySummary'
import type { PublishPolicyBody } from '../models/PublishPolicyBody'
import type { SavePolicyBody } from '../models/SavePolicyBody'
import type { SavePolicyResponse } from '../models/SavePolicyResponse'
import type { SuccessResponse } from '../models/SuccessResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class PoliciesV1Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Compliance Summaries
   * @returns PolicySummary Successful Response
   * @throws ApiError
   */
  public getComplianceSummaries({
    regionId,
    startDate,
    endDate,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter for policies and compliance records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter for policies and compliance records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
  }): CancelablePromise<Array<PolicySummary>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies/compliance/summary',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Compliance For Policy
   * @returns any Successful Response
   * @throws ApiError
   */
  public getComplianceForPolicy({
    regionId,
    constantPolicyUuid,
    startDate,
    endDate,
    operators,
    detail = false,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Constant Policy UUID to evaluate
     */
    constantPolicyUuid: string
    /**
     * Filter for policies and compliance records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter for policies and compliance records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Evaluate compliance for this operator
     */
    operators?: Array<string> | null
    /**
     * Whether to include detail compliance summary
     */
    detail?: boolean
  }): CancelablePromise<
    Array<
      | PolicyComplianceDetailDistribution
      | PolicyComplianceDetailNoRide
      | PolicyComplianceDetailNoParking
      | PolicyComplianceDetailOperatorDropOffs
      | PolicyComplianceDetailParkingTimeLimit
      | PolicyComplianceSummary
    >
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies/compliance/constant/{constant_policy_uuid}',
      path: {
        region_id: regionId,
        constant_policy_uuid: constantPolicyUuid,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        operators: operators,
        detail: detail,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * List Policies
   * List all policies in the region.
   * @returns ListPoliciesResponse Successful Response
   * @throws ApiError
   */
  public listPolicies({
    requestBody,
  }: {
    requestBody: ListPoliciesBody
  }): CancelablePromise<ListPoliciesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Delete Policy Endpoint
   * Delete a policy specified by UUID.
   * @returns SuccessResponse Successful Response
   * @throws ApiError
   */
  public deletePolicyEndpoint({
    requestBody,
  }: {
    requestBody: DeletePolicyBody
  }): CancelablePromise<SuccessResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/delete',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Publish Policy Api
   * Publishes or unpublishes the specified policy from the public API.
   * @returns SuccessResponse Successful Response
   * @throws ApiError
   */
  public publishPolicyApi({
    requestBody,
  }: {
    requestBody: PublishPolicyBody
  }): CancelablePromise<SuccessResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/publish',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Save Policy
   * Create or update a policy.
   *
   * If the request body has policy_uuid set this is assumed to mean update
   * an existing policy, otherwise a new policy is created. In either case
   * this returns a complete policy object reflecting the new policy.
   * @returns SavePolicyResponse Successful Response
   * @throws ApiError
   */
  public savePolicy({
    requestBody,
  }: {
    requestBody: SavePolicyBody
  }): CancelablePromise<SavePolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/save',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy By Constant Uuid
   * Get the active policy version referred to by a constant UUID
   * @returns GetPolicyResponse Successful Response
   * @throws ApiError
   */
  public getPolicyByConstantUuid({
    constantPolicyUuid,
  }: {
    constantPolicyUuid: string
  }): CancelablePromise<GetPolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/constant/{constant_policy_uuid}',
      path: {
        constant_policy_uuid: constantPolicyUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy
   * Get a specific policy
   * @returns GetPolicyResponse Successful Response
   * @throws ApiError
   */
  public getPolicy({ policyUuid }: { policyUuid: string }): CancelablePromise<GetPolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/policies/{policy_uuid}',
      path: {
        policy_uuid: policyUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
