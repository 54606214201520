/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_Schedule_ } from '../models/Page_Schedule_'
import type { Schedule } from '../models/Schedule'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class SchedulesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Schedules
   * @returns Page_Schedule_ Successful Response
   * @throws ApiError
   */
  public getSchedules({
    regionId,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page_Schedule_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/schedules',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Schedule
   * Get schedule from UUID.
   * @returns Schedule Successful Response
   * @throws ApiError
   */
  public getSchedule({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    id: string
  }): CancelablePromise<Schedule> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/schedules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
