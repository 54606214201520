export const PUBLIC_API_KEY = '97c11348858819b060124afe30dd7000'
export let APIPY_URL: string = `${window.location.origin}/apipy`
export let FASTAPI_INTERNAL_URL: string = `${window.location.origin}/internal`
export let FASTAPI_V2_URL: string = `${window.location.origin}/v2`

if (import.meta.env.DEV) {
  if (import.meta.env.VITEST) {
    APIPY_URL = 'http://127.0.0.1:8765/apipy'
    FASTAPI_INTERNAL_URL = 'http://127.0.0.1:8000/internal'
    FASTAPI_V2_URL = 'http://127.0.0.1:8000/v2'
  } else {
    APIPY_URL = `${window.location.protocol}//${window.location.hostname}:8765/apipy`
    FASTAPI_INTERNAL_URL = `${window.location.protocol}//${window.location.hostname}:8000/internal`
    FASTAPI_V2_URL = `${window.location.protocol}//${window.location.hostname}:8000/v2`
  }
}
