/**
 * This enum contains all query params that we use in the app.
 *
 * Guidance on naming query params:
 *  - snake case
 *  - singular if the param represents a single value (e.g., `OPERATOR`)
 *  - plural if the param represents an array of values (e.g., 'OPERATORS`)
 */
export enum QueryParam {
  ACTIVITIES = 'activities',
  AGGREGATION = 'aggregation',
  AGGREGATION_PERIOD = 'aggregation_period',
  AMOUNT_GTE = 'amount_gte',
  AMOUNT_LT = 'amount_lt',
  BATTERY_LIFE_LN = 'battery_life_ln',
  DATA_ISSUE_TYPE = 'data_issue_type',
  DAY_TYPES = 'day_types',
  DAYS_OF_WEEK = 'days_of_week',
  DESCRIPTION = 'description',
  DURATION_GTE = 'duration_gte',
  DURATION_LTE = 'duration_lte',
  EDITIING_SHAPE = 'editing_shape',
  END_DATE = 'end_date',
  END_DATE_GTE = 'end_date_gte',
  END_DATE_LT = 'end_date_lt',
  END_DATE_COMPARE = 'end_date_compare',
  END_TIME_HOUR = 'end_time_hour',
  END_TIME_HOUR_GTE = 'end_time_hour_gte',
  END_TIME_HOUR_LT = 'end_time_hour_lt',
  // TODO: Migrate to END_TIME_HHMM
  END_TIME_QTR_HOUR = 'end_time_qtr_hour',
  // TODO: Update policy library code to filter by id
  GEOGRAPHY_NAMES = 'geography_names',
  HAS_RATE = 'has_rate',
  HOURLY_PARKING_FEE = 'hourly_parking_fee',
  HOVERED_ISSUE = 'hovered_issue',
  HOVERED_SHAPE = 'hovered_shape',
  INCLUDE_DOCKED_STATION = 'include_docked_station',
  INCLUDE_OUTSIDE_BOUNDARY = 'include_outside_boundary',
  ISSUE_STATUS = 'issue_status',
  ISSUE_TYPE = 'issue_type',
  LOCATION_IDS = 'location_ids',
  METRIC = 'metric',
  METRIC_BY_SHAPE = 'metric_by_shape',
  METRIC_BY_TIME = 'metric_by_time',
  NAME = 'name',
  NETWORK_TYPE = 'network_type',
  OCCUPANCY_DATA_TYPE = 'occupancy_data_type',
  ONLY_SHOW_VIOLATIONS = 'only_show_violations',
  OPERATOR = 'operator',
  OPERATORS = 'operators',
  ORDER_BY = 'order_by',
  PAGE = 'page',
  PAGE_SIZE = 'page_size',
  PARKED_DURATION = 'parked_duration',
  PARKING_METRIC = 'parking_metric',
  PRICE_ELASTICITY = 'price_elasticity',
  PAYMENT_TYPES = 'payment_types',
  POLICY_COMPLIANCE_DETAIL = 'policy_compliance_detail',
  POLICY_ID = 'policy_id',
  POLICY_IDS = 'policy_ids',
  POLICY_STATUSES = 'policy_statuses',
  POLICY_TYPES = 'policy_types',
  PURPOSES = 'purposes',
  MAX_RATE_CHANGE = 'max_rate_change',
  RATE_TYPE = 'rate_type',
  REFERENCE_GEOGRAPHY = 'reference_geography',
  REFERENCE_SHAPES = 'reference_shapes',
  REPORT_TYPE = 'report_type',
  RESOLUTION_TIME = 'resolution_time',
  REVENUE_GTE = 'revenue_gte',
  REVENUE_LTE = 'revenue_lte',
  SCHEDULE_TYPE = 'schedule_type',
  SEARCH = 'search',
  SELECTED_BLOCK = 'selected_block',
  SELECTED_ISSUE = 'selected_issue',
  SELECTED_ROUTE = 'selected_route',
  SELECTED_SHAPE = 'selected_shape',
  SHAPE_LAYER = 'shape_layer',
  SHAPES = 'shapes',
  SHOW_CRASH_DATA = 'show_crash_data',
  SHOW_POLICY_EVENTS = 'show_policy_events',
  SHOW_POLICY_LAYERS = 'show_policy_layers',
  SORT_COLUMN_DESCENDING = 'sort_column_descending',
  SORT_COLUMN_KEY = 'sort_column_key',
  SORT_NAME = 'sort_name',
  SORT_ROW_DESCENDING = 'sort_row_descending',
  SORT_ROW_KEY = 'sort_row_key',
  START_DATE = 'start_date',
  START_DATE_GTE = 'start_date_gte',
  START_DATE_LT = 'start_date_lt',
  START_DATE_COMPARE = 'start_date_compare',
  START_TIME_HOUR = 'start_time_hour',
  START_TIME_HOUR_GTE = 'start_time_hour_gte',
  START_TIME_HOUR_LT = 'start_time_hour_lt',
  // TODO: Migrate to START_TIME_HHMM
  START_TIME_QTR_HOUR = 'start_time_qtr_hour',
  STOP_MULTIPLIER = 'stop_multiplier',
  TARGET_OCCUPANCY = 'target_occupancy',
  TEST_RATES = 'test_rates',
  TICKET_TYPES = 'ticket_types',
  TIME_PERIOD_TYPE = 'time_period_type',
  TIME_PERIOD_TYPE_DISTRICT = 'time_period_type_district',
  TIME_PERIOD_TYPE_REVENUE = 'time_period_type_revenue',
  TIME_PERIOD_TYPE_TRANSACTIONS = 'time_period_type_transactions',
  TRANSACTION_IDS = 'transaction_ids',
  USER_CLASSES = 'user_classes',
  USER_TYPES = 'user_types',
  VEHICLE_ID = 'vehicle_id',
  VEHICLE_STATE = 'vehicle_state',
  VEHICLE_TYPE = 'vehicle_type',
  VEHICLE_TYPES = 'vehicle_types',
}
