import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export enum GlobalModalId {
  PROFILE_SETTINGS = 'profile_settings',
}

interface GlobalModalContextProps {
  id: GlobalModalId | undefined
  setId: Dispatch<SetStateAction<GlobalModalId | undefined>>
}

export const GlobalModalContext = createContext<GlobalModalContextProps | undefined>(undefined)

export const useGlobalModalProvider = () => {
  const context = useContext(GlobalModalContext)
  if (context === undefined) {
    throw new Error('useGlobalModalProvider must be used within GlobalModalProvider')
  }
  return context
}
