import { DateUtils } from 'react-day-picker'
import { isDate, isNil } from 'lodash'
import { DateTime } from 'luxon'

import { DatePickerDropdownValue, DatePickerRange, DateType } from './types'

export const allDropDownOptions: DatePickerDropdownValue[] = [
  'custom',
  'singleDay',
  'noStart',
  'noEnd',
  'thisMonth',
  'lastMonth',
]

/**
 * Returns true if value is not undefined, null, or ''
 */
export function isNotNil(value: any): boolean {
  return !isNil(value) && value !== ''
}

/**
 * Returns true if potentially undefined or null dates are the same
 */
export function isDateMatch(day1: DateType, day2: DateType) {
  if (isNil(day1) && isNil(day2)) return true
  else if (!day1 || !day2) return false
  else return DateUtils.isSameDay(day1, day2)
}

/**
 * Returns true if string is made of numerical digits, '.' or '/' and up to 10 characters
 */
export function isValidDateInputText(value: string) {
  if (value.length > 10) return false
  if (value === '') return true
  const regex = /^[0-9./]+$/
  if (regex.test(value)) return true
  return false
}

/**
 * Returns a textual representation of a Date
 */
export function dateToText(date: DateType): string {
  if (!date) return ''
  if (isDate(date))
    return DateTime.fromJSDate(date).toLocaleString({
      ...DateTime.DATE_SHORT,
      day: '2-digit',
      month: '2-digit',
    })
  return date
}

/**
 * Returns a luxon DateTime object from a string \
 * Use `.isValid` to determine if Luxon successfully parsed string
 */
export function textToDate(value: string): DateTime {
  return DateTime.fromFormat(value, 'D', {
    locale: Intl.DateTimeFormat().resolvedOptions().locale,
  })
}

/**
 * Returns true if dates are from adjacent months
 */
export function isAdjacentMonths(startDate: Date, endDate: Date) {
  if (DateUtils.isSameMonth(startDate, endDate)) return false
  const lastMonth = getPreviousMonthDate(endDate)
  return DateUtils.isSameMonth(startDate, lastMonth)
}

/**
 * Returns the last day of the previous month as a new date
 */
export function getPreviousMonthDate(date: Date) {
  const lastMonth = DateUtils.clone(date)
  lastMonth.setDate(0)
  return lastMonth
}

/**
 * Returns first day of provided month as a new date
 */
export function firstOfMonth(date: Date) {
  const first = DateUtils.clone(date)
  first.setDate(1)
  return first
}

/**
 * Returns true if date range covers first of this month through today
 */
export function isThisMonth(from: Date, to: Date) {
  const endDate = new Date()
  const startDate = firstOfMonth(endDate)
  return DateUtils.isSameDay(startDate, from) && DateUtils.isSameDay(endDate, to)
}

/**
 * Returns true if date range covers all of last month
 */
export function isLastMonth(from: Date, to: Date) {
  const today = new Date()
  const endDate = getPreviousMonthDate(today)
  const startDate = firstOfMonth(endDate)
  return DateUtils.isSameDay(startDate, from) && DateUtils.isSameDay(endDate, to)
}

/**
 * Returns true if date is the last day of the month
 */
export function isLastDayOfMonth(date: Date) {
  const testDate = new Date(date.getTime())
  testDate.setDate(testDate.getDate() + 1)
  return testDate.getDate() === 1
}

export function convertDatePickerRangeToDateRange(datePickerRange: DatePickerRange) {
  return {
    start: datePickerRange.from ? DateTime.fromJSDate(datePickerRange.from) : undefined,
    end: datePickerRange.to && DateTime.fromJSDate(datePickerRange.to),
  }
}

export function getDateRangeInDays(from: Date, to: Date) {
  return DateTime.fromJSDate(to).diff(DateTime.fromJSDate(from), 'days').days
}
