/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page1K_PolicyV2RuleResponse_ } from '../models/Page1K_PolicyV2RuleResponse_'
import type { PolicyCategoryEnum } from '../models/PolicyCategoryEnum'
import type { PolicyType } from '../models/PolicyType'
import type { PolicyV2RuleResponse } from '../models/PolicyV2RuleResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RulesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Policy Rules
   * List all policy rules attached to.
   * @returns Page1K_PolicyV2RuleResponse_ Successful Response
   * @throws ApiError
   */
  public getPolicyRules({
    regionId,
    unit,
    search,
    maximum,
    orderBy,
    policyId,
    policyType,
    policyVehicleTypes,
    policyCategory,
    policyRegionId,
    policyShapeLayerId,
    policySearch,
    policyStartDateLt,
    policyStartDateGte,
    policyEndDateLt,
    policyEndDateGte,
    policyOrderBy,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    unit?: string | null
    search?: string | null
    maximum?: number | null
    orderBy?: string | null
    policyId?: string | null
    policyType?: PolicyType | null
    policyVehicleTypes?: string | null
    policyCategory?: PolicyCategoryEnum | null
    policyRegionId?: string | null
    policyShapeLayerId?: string | null
    policySearch?: string | null
    policyStartDateLt?: string | null
    policyStartDateGte?: string | null
    policyEndDateLt?: string | null
    policyEndDateGte?: string | null
    policyOrderBy?: string | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page1K_PolicyV2RuleResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rules',
      path: {
        region_id: regionId,
      },
      query: {
        unit: unit,
        search: search,
        maximum: maximum,
        order_by: orderBy,
        policy__id: policyId,
        policy__type: policyType,
        policy__vehicle_types: policyVehicleTypes,
        policy__category: policyCategory,
        policy__region_id: policyRegionId,
        policy__shape_layer_id: policyShapeLayerId,
        policy__search: policySearch,
        policy__start_date__lt: policyStartDateLt,
        policy__start_date__gte: policyStartDateGte,
        policy__end_date__lt: policyEndDateLt,
        policy__end_date__gte: policyEndDateGte,
        policy__order_by: policyOrderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Rule
   * Get a single curb policy rule from a UUID.
   * @returns PolicyV2RuleResponse Successful Response
   * @throws ApiError
   */
  public getPolicyRule({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy rule identifier
     */
    id: string
  }): CancelablePromise<PolicyV2RuleResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
