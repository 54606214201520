import { DateTime } from 'luxon'

import { Time } from './types'

export const MILLISECONDS_IN_A_SECOND = 1000
export const SECONDS_IN_A_MINUTE = 60
export const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_MINUTE
export const MINUTES_IN_AN_HOUR = 60
export const HOURS_IN_A_DAY = 24
export const DAYS_IN_A_WEEK = 7
export const DAYS_IN_A_NON_LEAP_YEAR = 365
export const MONTHS_IN_A_YEAR = 12
export const HOURS_IN_A_WEEK = HOURS_IN_A_DAY * DAYS_IN_A_WEEK
export const SECONDS_IN_AN_HOUR = SECONDS_IN_A_MINUTE * MINUTES_IN_AN_HOUR
export const SECONDS_IN_A_DAY = SECONDS_IN_AN_HOUR * HOURS_IN_A_DAY
export const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_A_SECOND * SECONDS_IN_A_DAY
export const MILLISECONDS_IN_A_WEEK = MILLISECONDS_IN_A_DAY * DAYS_IN_A_WEEK
export const MILLISECONDS_IN_A_YEAR = MILLISECONDS_IN_A_DAY * DAYS_IN_A_NON_LEAP_YEAR
export const MILLISECONDS_IN_AN_AVG_MONTH = MILLISECONDS_IN_A_YEAR / MONTHS_IN_A_YEAR

export const toHHMM = (value: Time | undefined): string => {
  if (!value) return ''
  const { hour, minute } = value
  if (hour == null || minute == null) return ''

  return [hour, minute].map(x => x.toString().padStart(2, '0')).join(':')
}

/**
 * text is a string to be parsed in HH:MM format e.g. "22:30"
 */
export const fromHHMM = (text: string | undefined) => {
  text = text?.trim()
  if (!text) return null

  // add a colon if none entered and text is more than 2 digits (2 digits or less should end up in the hours slot)
  if (!text.includes(':') && text.length > 2) {
    // 0900 -> 09:00
    // 900 -> 9:00
    text = text.slice(0, text.length - 2) + ':' + text.slice(text.length - 2)
  }

  let [hour, minute] = text
    .split(':')
    .map(n => parseInt(n, 10))
    .map(n => (isNaN(n) ? null : n))

  if (hour == null) return null

  if (minute == null) minute = 0

  if (hour === 24 && minute === 0) {
    // special case for 24:00;
    // we don't want to convert to 00:00 because this has a special meaning as the end of a time range
    // see https://en.wikipedia.org/wiki/24-hour_clock
    return { hour, minute }
  }

  const minutes = (hour * 60 + minute) % (24 * 60)

  // ensure result is between 00:00 and 24:00
  hour = Math.floor(minutes / 60)
  minute = minutes % 60

  return { hour, minute }
}

export const fromMinutesToHHMM = (minutesFromMidnight: number) => {
  if (minutesFromMidnight < 0 || minutesFromMidnight > MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY)
    throw RangeError(`Minutes must be between 0 and ${MINUTES_IN_AN_HOUR * HOURS_IN_A_DAY}`)

  const minutes = minutesFromMidnight % MINUTES_IN_AN_HOUR
  const hours = (minutesFromMidnight - minutes) / MINUTES_IN_AN_HOUR

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
}

export const fromHHMMtoMinutes = (hhmm: string) => {
  const [hour, minute] = hhmm.split(':').map(item => parseInt(item))

  return hour * MINUTES_IN_AN_HOUR + minute
}

/**
 * @param time Luxon DateTime instance
 * @returns Time string in local format
 */
export const formatTime = (time: DateTime) => time.toLocaleString(DateTime.TIME_SIMPLE)

/**
 * @param hour Hour (0-23) to be formatted as time string
 * @param minute Minute (0-59) to be formatted as time string
 * @returns Time string in local format
 */
export const formatHourMinute = (hour = 0, minute = 0) => {
  if (hour < 0 || hour > 24) throw RangeError('Hour must be between 0 and 24')
  if (minute < 0 || minute > 60) throw RangeError('Minute must be between 0 and 60')
  return formatTime(DateTime.now().set({ hour, minute }))
}

/**
 * @param timeString Time string in military HH:MM format
 * @returns Time string in local format
 */
export const formatTimeString = (timeString: string) => {
  const [hourString, minuteString] = timeString.split(':')
  const hour = parseInt(hourString)
  const minute = parseInt(minuteString)
  return formatHourMinute(hour, minute)
}

export const secondsToHoursMinutesSeconds = (seconds: number) => {
  const hours = Math.floor((seconds % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((seconds % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE)
    .toString()
    .padStart(2, '0')
  const secondsRemaining = Math.floor(seconds % SECONDS_IN_A_MINUTE)
    .toString()
    .padStart(2, '0')

  const values = [hours, minutes, secondsRemaining]
  return values.join(':')
}

export const secondsToDaysHoursMinutes = (seconds: number) => {
  const days = Math.floor(seconds / SECONDS_IN_A_DAY)
  const hours = Math.floor((seconds % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR)
  const minutes = Math.floor((seconds % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE)
  let text = ''
  const values = []
  if (days) {
    const unit = `day${days > 1 ? 's' : ''}`
    text += `${days} ${unit} `
    values.push({ count: days.toLocaleString(), unit })
  }
  if (hours) {
    const unit = `hr${hours > 1 ? 's' : ''}`
    text += `${hours} ${unit} `
    values.push({ count: hours.toLocaleString(), unit })
  }
  if (minutes) {
    const unit = `min${minutes > 1 ? 's' : ''}`
    text += `${minutes} ${unit}`
    values.push({ count: minutes.toLocaleString(), unit })
  }
  return { values, text: text.trim() || '0 mins' }
}

export const formatMinutesToHHMM = (val: number) => {
  if (val < 0 || val > 1440) throw RangeError('val must be between 0 and 1440, inclusive')
  return formatHourMinute(~~(val / 60), val % 60)
}

/**
 * Accepts a full datetime ISO string and a timezone and returns a time string
 * in HH:MM:SS AM/PM format.
 * @param dateTime - datetime ISO string, e.g. '2024-01-31T15:29:03-05:00'
 * @param timeZone - valid timezone code, e.g. 'America/Los_Angeles'
 * @returns - time string in HH:MM:SS AM/PM format, e.g. '3:29:03 PM'
 */
export const getTimeFromISOInTimezone = (dateTime: string, timeZone: string): string => {
  const date = new Date(dateTime)

  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone,
  })

  const timeString = formatter.format(date)
  return timeString
}

export const convertSecondsToMinutesOrHours = (seconds: number) => {
  if (seconds < 60) {
    return { value: seconds, unit: 'seconds' }
  } else if (seconds < 3600) {
    return { value: Math.floor((seconds / 60) * 10) / 10, unit: 'minutes' }
  } else {
    return { value: Math.floor((seconds / 3600) * 10) / 10, unit: 'hours' }
  }
}

export const timeTagText = (
  startTime: string | undefined | null,
  endTime: string | undefined | null
) => {
  if (!startTime || !endTime) return 'All Day'
  return `${formatTimeString(startTime)} - ${formatTimeString(endTime)}`
}
