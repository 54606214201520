/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParkingEventModel } from '../models/ParkingEventModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ParkingService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Parking Events
   * @returns ParkingEventModel Successful Response
   * @throws ApiError
   */
  public getParkingEvents({
    regionId,
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    purposes,
    operators,
    vehicleTypes,
    minParkingDuration,
    policyId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter evaluation records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter evaluation records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Filter metrics of evaluation records by the start_time in isoformat (inclusive). Only hour increments are supported currently. If an evaluation record started before the start_time but ended after it, the portion of the evaluation record after the start_time will be included in the metric.
     */
    startTime?: string | null
    /**
     * Filter metrics of evaluation records by the end_time in format isoformat (exclusive). Only hour increments are supported currently. If an evaluation record started before the end_time but ended after it, the portion of the evaluation record before the end_time will be included in the metric.
     */
    endTime?: string | null
    /**
     * Filter parking events by the days_of_the_week.
     */
    daysOfWeek?: Array<number> | null
    /**
     * Filter for parking_event purposes
     */
    purposes?: Array<string> | null
    /**
     * Filter for operators
     */
    operators?: Array<string> | null
    /**
     * Filter for vehicle types
     */
    vehicleTypes?: Array<string> | null
    /**
     * Filter events that have been parked longer (or equal to) than this parking duration (in seconds).
     */
    minParkingDuration?: number | null
    /**
     * Include whether the events match this policy and the revenue they generated from that policy
     */
    policyId?: string | null
  }): CancelablePromise<Array<ParkingEventModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/curb_parking/parking_events',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        purposes: purposes,
        operators: operators,
        vehicle_types: vehicleTypes,
        min_parking_duration: minParkingDuration,
        policy_id: policyId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
