import { FC } from 'react'
import classNames from 'classnames'
import { Checkbox as UICheckbox, CheckboxProps as UICheckboxProps } from 'semantic-ui-react'

import { Text } from '../Text'

import './index.scss'

interface CheckboxProps extends UICheckboxProps {
  dashed?: boolean
  editable?: boolean
  style?: 'primary'
}

export const Checkbox = ({
  className,
  dashed,
  editable = true,
  style,
  ...props
}: CheckboxProps) => {
  if (!editable)
    return <Uneditable checked={props.checked} className={className} label={props.label} />

  return (
    <UICheckbox
      className={classNames(
        'populus-checkbox',
        style || 'primary',
        dashed && 'dashed',
        !editable && 'uneditable',
        className
      )}
      {...props}
    />
  )
}

const Uneditable: FC<CheckboxProps> = ({ checked, className, label }) => {
  return (
    <div className={classNames('populus-checkbox-uneditable', className)}>
      <div className={classNames('checkbox-container', checked && 'checked')}></div>
      <Text className={classNames('checkbox-text', !checked && 'unchecked')} styleType="body">
        {label?.toString()}
      </Text>
    </div>
  )
}
