/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_ParkingCountsByShapeResponse_ } from '../models/Page_ParkingCountsByShapeResponse_'
import type { ParkingCountsByShapeAggregatedResponse } from '../models/ParkingCountsByShapeAggregatedResponse'
import type { ParkingOperatorDataType } from '../models/ParkingOperatorDataType'
import type { PaymentTypeEnum } from '../models/PaymentTypeEnum'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class PricingEngineService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Parking Counts By Shape
   * Returns actual and test parking counts by shape in a standard list format.
   * @returns Page_ParkingCountsByShapeResponse_ Successful Response
   * @throws ApiError
   */
  public getParkingCountsByShape({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    lowerTargetOccupancy,
    upperTargetOccupancy,
    maxRateChange,
    priceElasticity,
    orderBy = '+shape_name',
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Lower target limit for calculating the rate change
     */
    lowerTargetOccupancy: number
    /**
     * Upper target limit for calculating the rate change
     */
    upperTargetOccupancy: number
    /**
     * The max dollar amount by which the hourly parking rate can change
     */
    maxRateChange: number
    /**
     * Used for calculating the occupancy effect of a rate change
     */
    priceElasticity: number
    /**
     * metric to sort the results by, prepended by - (desc) or + (asc)
     */
    orderBy?: string
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number | null
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number | null
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number> | null
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string> | null
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum> | null
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string> | null
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page_ParkingCountsByShapeResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/pricing_engine/parking_counts',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        order_by: orderBy,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        operators: operators,
        payment_types: paymentTypes,
        shape_uuids: shapeUuids,
        data_type: dataType,
        lower_target_occupancy: lowerTargetOccupancy,
        upper_target_occupancy: upperTargetOccupancy,
        max_rate_change: maxRateChange,
        price_elasticity: priceElasticity,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Pricing Engine Vector
   * Generates vector tiles for pricing engine data, split by shape.
   * @returns string Successful Response
   * @throws ApiError
   */
  public getPricingEngineVector({
    regionId,
    shapeLayerUuid,
    z,
    x,
    y,
    startDate,
    endDate,
    lowerTargetOccupancy,
    upperTargetOccupancy,
    maxRateChange,
    priceElasticity,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Lower target limit for calculating the rate change
     */
    lowerTargetOccupancy: number
    /**
     * Upper target limit for calculating the rate change
     */
    upperTargetOccupancy: number
    /**
     * The max dollar amount by which the hourly parking rate can change
     */
    maxRateChange: number
    /**
     * Used for calculating the occupancy effect of a rate change
     */
    priceElasticity: number
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number | null
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number | null
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number> | null
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string> | null
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum> | null
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string> | null
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType | null
    ifModifiedSince?: string | null
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/pricing_engine/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        operators: operators,
        payment_types: paymentTypes,
        shape_uuids: shapeUuids,
        data_type: dataType,
        lower_target_occupancy: lowerTargetOccupancy,
        upper_target_occupancy: upperTargetOccupancy,
        max_rate_change: maxRateChange,
        price_elasticity: priceElasticity,
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Parking Counts By Shape Aggregated
   * Returns actual and test parking counts aggregated for a layer.
   * @returns ParkingCountsByShapeAggregatedResponse Successful Response
   * @throws ApiError
   */
  public getParkingCountsByShapeAggregated({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    lowerTargetOccupancy,
    upperTargetOccupancy,
    maxRateChange,
    priceElasticity,
    startTime,
    endTime,
    daysOfWeek,
    operators,
    paymentTypes,
    shapeUuids,
    dataType,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Filter for records that end after the provided ISO start date
     */
    startDate: string
    /**
     * Filter for records that start on or before the provided ISO end date
     */
    endDate: string
    /**
     * Lower target limit for calculating the rate change
     */
    lowerTargetOccupancy: number
    /**
     * Upper target limit for calculating the rate change
     */
    upperTargetOccupancy: number
    /**
     * The max dollar amount by which the hourly parking rate can change
     */
    maxRateChange: number
    /**
     * Used for calculating the occupancy effect of a rate change
     */
    priceElasticity: number
    /**
     * Filter for records that end after the provided ISO start time
     */
    startTime?: number | null
    /**
     * Filter for records that start on or before the provided ISO start time
     */
    endTime?: number | null
    /**
     * Filter for days of week
     */
    daysOfWeek?: Array<number> | null
    /**
     * Filter for records that are in this list of operators
     */
    operators?: Array<string> | null
    /**
     * Filter for records that are in this list of payment types
     */
    paymentTypes?: Array<PaymentTypeEnum> | null
    /**
     * Filter for one or more shapes or districts
     */
    shapeUuids?: Array<string> | null
    /**
     * Selector for payments or sensor based occupancy
     */
    dataType?: ParkingOperatorDataType | null
  }): CancelablePromise<ParkingCountsByShapeAggregatedResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/pricing_engine/parking_counts_aggregated',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        operators: operators,
        payment_types: paymentTypes,
        shape_uuids: shapeUuids,
        data_type: dataType,
        lower_target_occupancy: lowerTargetOccupancy,
        upper_target_occupancy: upperTargetOccupancy,
        max_rate_change: maxRateChange,
        price_elasticity: priceElasticity,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
