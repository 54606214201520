import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router'

import { DrawerItemDropdown } from '@/components/drawer/DrawerItemDropdown'
import { DrawerTitle } from '@/components/drawer/DrawerTitle'
import { DashboardGroup, getDashboardOptions } from '@/modules/dashboard/utils/dashboardConstants'
import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import {
  useMobilityDistributionMapAccess,
  useMobilityIssuesMapAccess,
  useMobilityLiveMapAccess,
  useMobilityOperatorDropOffsMapAccess,
  useMobilityParkingMapAccess,
  useMobilityPoliciesAccess,
  useMobilityPolicyComplianceAccess,
  useMobilityReportingExportBikeshareStationReportAccess,
  useMobilityReportingExportDataAccess,
  useMobilityReportingExportReportsAccess,
  useMobilityReportingMobilityMetricsAccess,
  useMobilityReportingParkingAccess,
  useMobilityReportingTimeDistanceAccess,
  useMobilityReportingTripCountsAccess,
  useMobilityRoutesMapAccess,
  useMobilityTripsMapAccess,
} from '@/modules/permissions/pageAccessHooks'

import { DrawerItemNavLink } from './DrawerItemNavLink'
import { useCurrentRegion } from './hooks'
import { PATHS } from './paths'
import { generatePathWithRegionId } from './utils'

/**
 * Drawer links for `/mobility/maps/*`
 */
const MobilityMapsDrawerLinks: FC<{ regionId?: string }> = ({ regionId }) => {
  const { t } = useTranslation()
  const liveMapAccess = useMobilityLiveMapAccess()
  const issuesMapAccess = useMobilityIssuesMapAccess()
  const distributionMapAccess = useMobilityDistributionMapAccess()
  const operatorDropOffMapAccess = useMobilityOperatorDropOffsMapAccess()
  const parkingMapAccess = useMobilityParkingMapAccess()
  const routesMapAccess = useMobilityRoutesMapAccess()
  const tripsMapAccess = useMobilityTripsMapAccess()

  return (
    <>
      <DrawerTitle title={t('urlRouting.drawerTitleMaps', 'Maps')} />
      <DrawerItemNavLink
        disabled={!liveMapAccess}
        text={t('urlRouting.drawerItemLive', 'Live')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.LIVE, regionId)}
      />
      <DrawerItemNavLink
        disabled={!issuesMapAccess}
        text={t('urlRouting.drawerItem311', '311')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS['311'], regionId)}
      />
      <DrawerItemNavLink
        disabled={!distributionMapAccess}
        text={t('urlRouting.drawerItemVehicleDistribution', 'Vehicle Distribution')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.DISTRIBUTION, regionId)}
      />
      <DrawerItemNavLink
        disabled={!operatorDropOffMapAccess}
        text={t('urlRouting.drawerItemOperatorDropOffs', 'Operator Drop-offs')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.OPERATOR_DROP_OFFS, regionId)}
      />
      <DrawerItemNavLink
        disabled={!parkingMapAccess}
        text={t('urlRouting.drawerItemParking', 'Parking')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.PARKING, regionId)}
      />
      <DrawerItemNavLink
        disabled={!routesMapAccess}
        text={t('urlRouting.drawerItemRoutes', 'Routes')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.ROUTES, regionId)}
      />
      <DrawerItemNavLink
        disabled={!tripsMapAccess}
        text={t('urlRouting.drawerItemTrips', 'Trips')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MAPS.TRIPS, regionId)}
      />
    </>
  )
}

/**
 * NavLinks for some reporting pages link to a single URL but should appear active for multiple URLs. This function checks for a match among a group of URLs.
 * @param dashboardGroup grouping of reporting pages to search for match
 * @param pathname current location.pathname
 * @returns boolean
 */
function isReportingDashboardPathMatch(dashboardGroup: DashboardGroup, pathname: string) {
  return getDashboardOptions().some(
    ({ group, value }) => group === dashboardGroup && !!matchPath(value, pathname)
  )
}
/**
 * Drawer links for `/mobility/reporting/*`
 */
const MobilityReportingDrawerLinks: FC<{ regionId?: string }> = ({ regionId }) => {
  const location = useLocation()
  const { setId } = useGlobalModalProvider()
  const { t } = useTranslation()

  const mobilityReportingExportDataAccess = useMobilityReportingExportDataAccess()
  const mobilityReportingExportReportsAccess = useMobilityReportingExportReportsAccess()
  const mobilityReportingBikeshareStationAccess =
    useMobilityReportingExportBikeshareStationReportAccess()
  const mobilityReportingTripCountsAccess = useMobilityReportingTripCountsAccess()
  const mobilityReportingMobilityMetricsAccess = useMobilityReportingMobilityMetricsAccess()
  const mobilityReportingTimeDistanceAccess = useMobilityReportingTimeDistanceAccess()
  const mobilityReportingParkingAccess = useMobilityReportingParkingAccess()

  return (
    <>
      <DrawerTitle title={t('urlRouting.drawerTitleReporting', 'Reporting')} />
      <DrawerItemNavLink
        disabled={!mobilityReportingTripCountsAccess}
        text={t('urlRouting.drawerItemVehicleTripCounts', 'Vehicle & Trip Counts')}
        to={generatePathWithRegionId(PATHS.MOBILITY.REPORTING.TRIP_COUNTS, regionId)}
      />
      <DrawerItemNavLink
        disabled={!mobilityReportingMobilityMetricsAccess}
        text={t('urlRouting.drawerItemMobilityMetrics', 'Mobility Metrics')}
        to={generatePathWithRegionId(PATHS.MOBILITY.REPORTING.MOBILITY_METRICS, regionId)}
      />
      <DrawerItemNavLink
        disabled={!mobilityReportingTimeDistanceAccess}
        isActive={isReportingDashboardPathMatch(DashboardGroup.TRIP, location.pathname)}
        text={t('urlRouting.drawerItemTimeDistance', 'Time & Distance')}
        to={generatePathWithRegionId(PATHS.MOBILITY.REPORTING.TRIP_DISTANCE, regionId)}
      />
      <DrawerItemNavLink
        disabled={!mobilityReportingParkingAccess}
        isActive={isReportingDashboardPathMatch(DashboardGroup.PARKING, location.pathname)}
        text={t('urlRouting.drawerItemMicromobilityParking', 'Micromobility Parking')}
        to={generatePathWithRegionId(PATHS.MOBILITY.REPORTING.PARKING_TIME, regionId)}
      />
      <DrawerItemNavLink
        disabled={
          !(
            mobilityReportingExportDataAccess ||
            mobilityReportingBikeshareStationAccess ||
            mobilityReportingExportReportsAccess
          )
        }
        text={t('urlRouting.drawerItemExport', 'Export')}
        to={generatePathWithRegionId(PATHS.MOBILITY.REPORTING.EXPORT, regionId)}
      />
    </>
  )
}

/**
 * Drawer links for `/mobility/policies/*`
 */
const MobilityPoliciesDrawerLinks: FC<{ regionId?: string }> = ({ regionId }) => {
  const { t } = useTranslation()
  const policiesAccess = useMobilityPoliciesAccess()
  const policyComplianceAccess = useMobilityPolicyComplianceAccess()

  return (
    <>
      <DrawerTitle title={t('urlRouting.drawerTitlePolicies', 'Policies')} />
      <DrawerItemNavLink
        disabled={!policiesAccess}
        text={t('urlRouting.drawerItemPoliciesLibrary', 'Policies Library')}
        to={generatePathWithRegionId(PATHS.MOBILITY.POLICIES._PATH, regionId)}
      />
      <DrawerItemNavLink
        disabled={!policyComplianceAccess}
        text={t('urlRouting.drawerItemCompliance', 'Compliance')}
        to={generatePathWithRegionId(PATHS.MOBILITY.POLICIES.COMPLIANCE._PATH, regionId)}
      />
    </>
  )
}

/**
 * Renders drawer links associated with the shelf item selected by a user
 * @param activePath - path representing the shelf item selected by a user
 */
const DrawerLinks = ({ activePath }: { activePath?: string }) => {
  const {
    data: { regionId },
  } = useCurrentRegion()

  if (!regionId) return null
  switch (activePath) {
    case PATHS.MOBILITY.MAPS._ANY:
      return <MobilityMapsDrawerLinks regionId={regionId} />
    case PATHS.MOBILITY.REPORTING._ANY:
      return <MobilityReportingDrawerLinks regionId={regionId} />
    case PATHS.MOBILITY.POLICIES._ANY:
      return <MobilityPoliciesDrawerLinks regionId={regionId} />
    default:
      return null
  }
}

export default DrawerLinks
