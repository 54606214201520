/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Page_MDSPolicy_ } from '../models/Page_MDSPolicy_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MdsPoliciesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * List Region Mds Policies App
   * List all of the active MDS policies for the given region in the format we
   * want for our external facing maps pages.
   *
   * The schema of this response does not perfectly match that of MDS policies
   * (which is not paginated), but the individual policy documents are close.
   * @returns Page_MDSPolicy_ Successful Response
   * @throws ApiError
   */
  public listRegionMdsPoliciesApp({
    regionId,
    page = 1,
    size = 50,
  }: {
    regionId: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page_MDSPolicy_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mds/policies_for_map',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
