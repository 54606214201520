/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CrashDataResponseModel } from '../models/CrashDataResponseModel'
import type { IssueResponse } from '../models/IssueResponse'
import type { IssueType } from '../models/IssueType'
import type { Page_RegionResponse_ } from '../models/Page_RegionResponse_'
import type { RegionResponse } from '../models/RegionResponse'
import type { WeatherResultModel } from '../models/WeatherResultModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RegionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Regions
   * List all visible regions for the requesting user
   * @returns Page_RegionResponse_ Successful Response
   * @throws ApiError
   */
  public getRegions({
    page = 1,
    size = 50,
    text,
    regionId,
    organizationUuid,
    search,
    orderBy,
  }: {
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
    text?: string | null
    regionId?: string | null
    organizationUuid?: string | null
    search?: string | null
    orderBy?: string | null
  }): CancelablePromise<Page_RegionResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions',
      query: {
        page: page,
        size: size,
        text: text,
        region_id: regionId,
        organization_uuid: organizationUuid,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Region By Id
   * Retrieve a region by slug, only showing it if it is available for the requesting user
   * @returns RegionResponse Successful Response
   * @throws ApiError
   */
  public getRegionById({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<RegionResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Transit Crash Data For Region
   * This endpoint provides the transit crash date for a given range of days and a given region
   * @returns CrashDataResponseModel Successful Response
   * @throws ApiError
   */
  public getTransitCrashDataForRegion({
    regionId,
    startDate,
    endDate,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
  }): CancelablePromise<Array<CrashDataResponseModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/crash_data',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get All Issues
   * List all issues for the current region
   * @returns IssueResponse Successful Response
   * @throws ApiError
   */
  public getAllIssues({
    regionId,
    serviceCode,
    startDate,
    endDate,
    status,
    resolutionTime,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    serviceCode: string
    /**
     * Filter issues that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter issues that start before the provided ISO end date (exclusive)
     */
    endDate: string
    status?: 'open' | 'closed' | null
    resolutionTime?: string | null
    accept?: string
  }): CancelablePromise<Array<IssueResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/issues',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        service_code: serviceCode,
        start_date: startDate,
        end_date: endDate,
        status: status,
        resolution_time: resolutionTime,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Issue Types
   * Retrieve issue types for a region
   * @returns IssueType Successful Response
   * @throws ApiError
   */
  public getIssueTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<IssueType>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/issues/types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Issue By Id
   * Retrieve an issue by ID
   * @returns IssueResponse Successful Response
   * @throws ApiError
   */
  public getIssueById({
    regionId,
    issueId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Issue uuid
     */
    issueId: string
  }): CancelablePromise<IssueResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/issues/{issue_id}',
      path: {
        region_id: regionId,
        issue_id: issueId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * List Weather
   * Returns weather data for a given region.
   * @returns WeatherResultModel Successful Response
   * @throws ApiError
   */
  public listWeather({
    regionId,
    dayStrGte,
    dayStrLte,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    dayStrGte: string
    dayStrLte: string
  }): CancelablePromise<Array<WeatherResultModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/weather',
      path: {
        region_id: regionId,
      },
      query: {
        day_str__gte: dayStrGte,
        day_str__lte: dayStrLte,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Weather By Day
   * Returns weather data for a given region on a specific date.
   * @returns WeatherResultModel Successful Response
   * @throws ApiError
   */
  public getWeatherByDay({
    regionId,
    day,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    day: string
  }): CancelablePromise<WeatherResultModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/weather/{day}',
      path: {
        region_id: regionId,
        day: day,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
